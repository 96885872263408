// Boxes
$mobile: 480px; // 480
$tablet: 768px;
$tablet-2: 675px;
$large: 1024px;
$all: 90%;

// customized
$fif-hund: 500px;
// END Boxes

$icons: '/assets/images/ui-icons.png';

// Elements
$footer-height: 100px;
// END Elements
