// Imports
  @import 'modules/_fonts';
  @import 'modules/_all';
// END Imports

.dd-wrapper {
  position: relative;
}

.dd-header-section {
  margin-bottom: 30px;
}


//Section slider
.dd-section-slider {
  position: relative;
  max-height: 405px;
  overflow: hidden;
  visibility: hidden;
  margin-bottom: 54px;
  border-top: 3px solid $gray;

  .dd-bxslider {
    li {
      overflow: hidden;
      height: 36vw;
      max-height: 405px;

      @media screen and (min-width: 1100px){
        height: 405px;
      }
    }

    li img {
      max-width: none;
      max-height: 100%;
      position: absolute;
      top: -9999px;
      bottom: -9999px;
      left: -9999px;
      right: -9999px;
      margin: auto;
    }
  }

  .dd-container-controls{
    background: $black;

    .dd-arrow {
      background-image: url(/assets/img/site/slider-arrows.png);
      background-repeat: no-repeat;
      width: 33px;
      height: 50px;
      cursor: pointer;
    }

    .dd-arrow-left {
      background-position: left center;
    }

    .dd-arrow-right  {
      background-position: right center;
    }
  }

  .dd-normal-box {
    width: 1160px;
    position: absolute;
    top: 50%;
    margin-top: -32px;
    left: 0px;
    right: 0px;
    height: 0px;

    @media screen and (max-width: 1180px) {
      width: 100%;
    }
  }
}
//end Section slider

//----------------------//---------------------------//

//Section search
.dd-section-search {
  width: 100%;
  position: absolute;
  top: 3px;
  left: 0;
  z-index: 999;

  .dd-container-form {
    padding: 45px 0;
    display: none;
  }

  .dd-icon-search {
    background-image: url(/assets/img/site/icon-search.png);
    background-position: center top;
    background-repeat: no-repeat;
    width: 125px;
    height: 46px;
    position: absolute;
    right: 0;
    top: -8px;
    cursor: pointer;
    z-index: 1;
    text-align: center;
    font-family: $OpenSans-Semibold;
    color: $white;
    font-size: 10px;
    padding-top: 2px;

    .fa {
      font-size: 14px;
      margin-top: 2px;
    }
  }

  .dd-box-form {
    width: 70%;
    margin: 0 auto;

    @media screen and (max-width: 770px) {
      width: 100%;
    }

    .dd-line-form {
      margin-bottom: 0;
    }

    .dd-col {
      width: 78%;
      vertical-align: top;

      @media screen and (max-width: 500px) {
        width: 100%;
        margin-bottom: 15px;
      }
    }

    .dd-container-btn-send {
      vertical-align: bottom;
      height: 44px;
      margin-left: 3%;
      width: 19%;

      @media screen and (max-width: 500px) {
        width: 100%;
        margin-left: 0;
      }

      button {
        border: none;
        height: 44px;
        padding: 11px 0;
      }
    }
  }
}
//end Section search

//----------------------//---------------------------//

// Section services
.dd-section-services {
  margin-bottom: 66px;

  .dd-services-list {
    li {
      width: 25%;
      font-size: 15px;
      padding: 0 13px;
      vertical-align: top;
      text-transform: uppercase;

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }

      @media screen and (max-width: $tablet) {
        width: 50%;
        padding: 0;
        margin: 15px 0;

        &:nth-child(odd) {
          padding-right: 16px;
        }

        &:nth-child(even) {
          padding-left: 16px;
        }
      }

      @media screen and (max-width: $mobile) {
        width: 100%;

        &:nth-child(odd) {
          padding-right: 0;
        }

        &:nth-child(even) {
          padding-left: 0;
        }
      }
    }

    header {
      padding: 36px 0;
    }


    article {
      border: 1px solid $gray;
      @include border-radius(6px);
    }

    img {
      margin-bottom: 11px;
    }

    figcaption {
      font-size: 16px;

      @media screen and (max-width: 920px) {
        font-size: 13px;
      }

      @media screen and (max-width: $tablet) {
        font-size: 16px;
      }
    }

    .dd-btn-more {
      width: 100%;
      display: block;
      color: $dark-gray-w2;
      font-size: 13px;
      padding: 13px 0;
      border-top: 1px solid $gray;

      &:hover {
        background-color: $blue;
        color: $white;
      }
    }
  }
}
// end Section services

//----------------------//---------------------------//

// Section specialties
.dd-section-specialties {
  background-image: url(/assets/img/site/bg-section-specialties.png);
  background-position: center center;
  background-repeat: no-repeat;
  padding-top: 51px;
  padding-bottom: 65px;
  margin-bottom: 53px;

  .dd-header-section {
    margin-bottom: 35px;
  }

  .dd-title-sec-df,
  .dd-subtitl-sec-df {
    color: $white;
  }

  .dd-title-sec-df {
    font-family: $Raleway-Light;
    font-size: 33px;
    margin-bottom: 11px;
  }

  .dd-subtitl-sec-df {
    padding-bottom: 0;
  }

  ::after {
    display: none;
  }

  .dd-box-sel {
    max-width: 365px;
    margin: 0 auto;
    width: 100%;
  }

  select {
    height: 50px;
    border: none;
  }

  .dd-arrow-down {
    background-image: url(/assets/img/site/icon-select-down.png);
    background-position: center center;
    background-repeat: no-repeat;
    width: 25px;
    height: 30px;
    top: 0;
    right: 17px;
    pointer-events: none;
  }
}
// end Section specialties

//----------------------//---------------------------//

//Section last news
.dd-section-last-news {
  margin-bottom: 67px;

  .dd-last-news-list {
    margin-bottom: 59px;

    li {
      width: 33.333%;
      vertical-align: top;

      @media screen and (max-width: $tablet) {
        width: 50%;

        &:nth-child(3) {
          display: none;
        }
      }

      @media screen and (max-width: $mobile) {
        width: 100%;
        margin-bottom: 60px;

        &:last-child {
          margin-bottom: 0;
        }
      }

    }

    .dd-header-news {
      min-height: 249px;
    }

    .dd-date {
      bottom: 16px;
      left: 23px;
      width: 47px;
      padding-bottom: 9px;
      line-height: 9px;
      padding-top: 15px;
    }

    article {
      padding: 0 11px;
    }

    .dd-content {
      border-left: 1px solid $gray;
      border-right: 1px solid $gray;
    }

    .dd-header {
      border-bottom: 1px solid $gray;
      padding: 22px 15px 29px 15px;
      min-height: 172px;
    }

    h1 {
      font-size: 18px;
      font-family: $Raleway-Regular;
      color: $dark-gray-w2;
      line-height: 24px;

      &:hover {
        color: $blue;
      }
    }

    .dd-content-text {
      padding: 25px 15px 29px 15px;
      min-height: 96px;
    }

    p {
      font-size: 14px;
      line-height: 24px;
    }

    .dd-btns-options {
      .dd-btn {
        width: 50%;

        &.dd-btn-more-full {
          width: 100%;
        }
      }

      .dd-btn-author,
      .dd-btn-more {
        padding-left: 7px;
        padding-right: 7px;
      }
    }
  }

  .dd-see-all {
    background-color: $blue;
    width: 152px;
    font-size: 14px;
    color: $white;
    text-transform: uppercase;
    display: block;
    margin: 0 auto;
    padding-left: 19px;
    padding: 10px 0 10px 19px;
    @include border-radius(20px);

    span {
      font-size: 21px;
      position: absolute;
      right: 11px;
      top: 7px;
      border: 1px solid $white;
      width: 23px;
      height: 23px;
      text-align: center;
      @include border-radius(50%);
      line-height: 22px;
    }

    &:hover {
      background-color: $yellow;
      color: $dark-gray-w2;

      span {
        border: 1px solid $dark-gray-w2;
      }
    }
  }
}
//end Section last news

.dd-section-plans {
  border-top: 1px solid $gray;
  padding-top: 53px;
  margin-bottom: 8px;
  visibility: hidden;

  .bx-wrapper {
    @include calc('width', '100% - 60px');
    margin: 0 auto;
    vertical-align: middle;
    display: block;
  }

  .fa-chevron-left,
  .fa-chevron-right {
    color: $dark-gray-w3;
    font-size: 23px;
    vertical-align: middle;
    cursor: pointer;
    position: absolute;
    top: 50%;
    margin-top: -10px;
    @include fade;

    &:hover {
      color: $blue;
    }
  }

  .fa-chevron-left {
    left: 0;
  }

  .fa-chevron-right {
    right: 0;
  }

  li {
    article {
      height: 42px;
    }
  }

  li img {
    @media screen and (min-width: $tablet) {
      filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale"); /* Firefox 3.5+, IE10 */
      filter: gray; /* IE6-9 */
      -webkit-filter: grayscale(100%); /* Chrome 19+ & Safari 6+ */
      -webkit-transition: all .6s ease; /* Fade to color for Chrome and Safari */
      -webkit-backface-visibility: hidden; /* Fix for transition flickering */
    }

    &:hover {
      filter: none;
      -webkit-filter: grayscale(0%);
    }
  }
}
