// Colors

// default
$black: #000;
$black-opace: rgba($black, .75);

$footer-bg: #ccc;

$transparent: transparent;

$white: #fff;

$pink: #EF5BA1;

// colorfull
$yellow: #f9e728;
$blue: #2ba0c2;

//grayscale
$gray: #dddddd;
$dark-gray-w1: #666666;
$dark-gray-w2: #444444;
$dark-gray-w3: #999999;
$light-gray: #f3f3f3;

// main
$main: $black;
$main-link: $black;
$main-link-hover: $blue;

// End Colors

.dd-m-c {
    &--blue {
        color: $blue;
    }

    &--pink {
        color: $pink;
    }
}