// Fonts
$font-path: '/assets/fonts';
$font: Arial, sans-serif;

//Imports
@font-face {
    font-family: 'Raleway-Regular';
    src: url('#{$font-path}/Raleway-Regular.eot');
    src: url('#{$font-path}/Raleway-Regular.eot?#iefix') format('embedded-opentype');
    src: local('Raleway-Regular'),
    url('#{$font-path}/Raleway-Regular.woff2') format('woff2'),
    url('#{$font-path}/Raleway-Regular.woff') format('woff'),
    url('#{$font-path}/Raleway-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Raleway-Light';
    src: url('#{$font-path}/Raleway-Light.eot');
    src: url('#{$font-path}/Raleway-Light.eot?#iefix') format('embedded-opentype');
    src: local('Raleway-Light'),
    url('#{$font-path}/Raleway-Light.woff2') format('woff2'),
    url('#{$font-path}/Raleway-Light.woff') format('woff'),
    url('#{$font-path}/Raleway-Light.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'OpenSans-Light';
    src: url('#{$font-path}/OpenSans-Light.eot');
    src: url('#{$font-path}/OpenSans-Light.eot?#iefix') format('embedded-opentype');
    src: local('OpenSans-Light'),
    url('#{$font-path}/OpenSans-Light.woff2') format('woff2'),
    url('#{$font-path}/OpenSans-Light.woff') format('woff'),
    url('#{$font-path}/OpenSans-Light.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'OpenSans-Semibold';
    src: url('#{$font-path}/OpenSans-Semibold.eot');
    src: url('#{$font-path}/OpenSans-Semibold.eot?#iefix') format('embedded-opentype');
    src: local('OpenSans-Semibold'),
    url('#{$font-path}/OpenSans-Semibold.woff2') format('woff2'),
    url('#{$font-path}/OpenSans-Semibold.woff') format('woff'),
    url('#{$font-path}/OpenSans-Semibold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'icomoon';
    src: url('#{$font-path}/icomoon.eot?k7mx2d');
    src: url('#{$font-path}/icomoon.eot?k7mx2d#iefix') format('embedded-opentype'),
    url('#{$font-path}/icomoon.ttf?k7mx2d') format('truetype'),
    url('#{$font-path}/icomoon.woff?k7mx2d') format('woff'),
    url('#{$font-path}/icomoon.svg?k7mx2d#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}

.dd-f-icomoon {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    &--icon-boy:before {
        content: "\e900";
    }

    &--icon-girl:before {
        content: "\e901";
    }
}

//END Imports

$Raleway-Regular: 'Raleway-Regular';
$Raleway-Light: 'Raleway-Light';
$OpenSans-Semibold: 'OpenSans-Semibold';
$OpenSans-Light: 'OpenSans-Light';
// END Fonts
